


















































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import {SupportedServerCollection} from '@/model/collection/SupportedServerCollection'
import {ListSupportedServerSchema} from '@/schema/resource/SupportedServer/ListSupportedServerSchema'
import {SupportedServer} from '@/model/resource/SupportedServer'

@Component
export default class ListSupportedServerView extends Mixins(MixinRouteMatch) {
  schema = new ListSupportedServerSchema()
  collection = new SupportedServerCollection()

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.setOrderBy('order')
    await this.populate()
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: SupportedServer) {
    this.$nav.pushByName('editSupportedServer', item.$id)
  }

  async removeItem(item: SupportedServer) {
    await this.$dialog.remove(item)
    await item.removeSupportedServer()
    await this.collection.queryAsPage()
  }

  async changeOrder(item: SupportedServer, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await this.$await.run('hardQuery', () =>
      this.collection.listSupportedServerAdmin()
    )
  }
}
