/**
 * List Schema of Supported Server
 * @author Yumi
 */

import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {SupportedServer} from '@/model/resource/SupportedServer'
import {$} from '@/facade'

export class ListSupportedServerSchema extends DefaultSchema {
  readonly name = 'ListSupportedServer'

  readonly fieldSet: FieldSet<SupportedServer> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    serverImageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.serverImageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'h-48 max-w-xs md:max-w-md lg:max-w-lg',
      },
    }),
    name: (): FieldComponent => ({
      is: Component.Render,
    }),
    discordLink: (): FieldComponent => ({
      is: Component.Render,
    }),
    private: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.bool(schema.model.private),
      },
    }),
  }
}
